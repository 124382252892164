/* START On click scroll to position */
const scrollAnimation = () => {
    $("body").on("click", ".js-scroll", function (event) {
        const target = $($(this).attr("href"));
        console.log("hello");
        if (target.length) {
            event.preventDefault();
            $("body").removeClass("open");
            $("html, body").animate(
                {
                    scrollTop: target.offset().top,
                },
                1000
            );
        }
    });
};

/* START Modaal */
const openModaalLarge = () => {
    $(".js-open-modaal").modaal({
        custom_class: "modal-large",
        overlay_close: true,
        background: "#000",
        after_open: function () {
            jQuery(".js-open-modaal-inside").modaal({
                custom_class: "modal-inside",
                background_scroll: true,
            });
            $(".modal-large .modaal-close").on("click", function () {
                jQuery(".js-open-modaal").modaal("close");
            });
        },
    });
};

/* START Expand list with arrow rotate */
const expandText = () => {
    $('body').on('click', '.js-expand-element', function (event) {
        event.preventDefault();
        const visible = $(this).attr('visible');
        if (visible == 'true') {
            $(this).attr('visible', false);
            $(this).css({ transform: 'rotate(315deg)' });
            $(this).closest('.js-accordion').find('.js-hidden-area').slideUp();
        } else {
            $(this).attr('visible', true);
            $(this).css({ transform: 'rotate(0deg)' });
            $(this).closest('.js-accordion').find('.js-hidden-area').slideDown();
        }
    });
}

$(function () {
    scrollAnimation();
    expandText()
    // openModaalLarge();
});

window.addEventListener("load", (event) => {
    $(".js-open-modaal").modaal({
        background: "#000",
    });
    $(".js-open-modaal--small").modaal({
        background: "#000",
        width: 640,
    });
});
